<template>
  <v-container
    class="px-3"
    style="height: 100vh; background-color: white"
    fluid
  >
    <p class="pt-1 pb-6" style="color: #532e88; font-weight: bolder">
      <v-icon color="primary" class="pl-7 pr-2">fas fa-comment</v-icon>
      Atendimentos
    </p>
    <v-card style="height: 95vh">
      <v-row class="pt-2 justify-center">
        <v-col cols="10" class="pb-0 mb-0">
          <v-form
            id="ticketForm"
            ref="ticketForm"
            @submit.prevent="createTicket"
          >
            <v-layout row wrap>
              <v-row>
                <v-col>
                  <h2 class="generic-title">{{ caseData.subject }}</h2>
                  <v-flex>
                    <v-select
                        label="Benefício / Operadora"
                        :items="contracts"
                        item-text="text"
                        return-object
                        v-model="caseData.contract"
                        placeholder="Selecione um benefício"
                        :rules="[rules.required]"
                        @change="loadApplicants()"
                    />
                  </v-flex>
                  <v-flex>
                    <v-select
                      label="Solicitante do atendimento"
                      :items="applicants"
                      item-text="personName"
                      return-object
                      v-model="beneficiarySelected"
                      placeholder="Selecione um solicitante"
                      :rules="[rules.required]"
                      :disabled="!caseData.contract"
                    />
                  </v-flex>
                  <v-flex xs12>
                    <v-text-field
                      label="Solicitação"
                      v-model="caseData.description"
                      placeholder="Descreva sua Solicitação"
                      :rules="[rules.required]"
                    ></v-text-field>
                  </v-flex>
                  <v-flex>
                    <form-file-upload
                      :outline="true"
                      ref="customFileUpload"
                      :maximum="10"
                      :limit-file-size="12"
                      @update="setAttachments"
                    />
                  </v-flex>
                </v-col>
              </v-row>
            </v-layout>
          </v-form>
        </v-col>
        <v-row justify="center" class="btn-position px-2">
          <v-col cols="11">
            <GenericBtn
              :loading="busy"
              :disabled="busy"
              class="mt-2"
              :btn-props="{
                block: true,
                large: true,
                color: 'primary',
                type: 'submit',
                form: 'ticketForm',
              }"
              >Solicitar
            </GenericBtn>
            <GenericBtn
              color="secondary"
              class="mt-5"
              :btn-props="{
                block: true,
                large: true,
                type: 'submit',
                form: 'ticketForm',
              }"
              :on-click="() => $router.back()"
              >Cancelar
            </GenericBtn>
          </v-col>
        </v-row>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import { mapMutations, mapGetters, mapState } from "vuex";
import { formrules } from "@/common/formrules";
import FormFileUpload from "../../components/generic-upload/FormFileUpload";
import $auth from "../../common/auth";
import CaseService from "../../services/odoo/CaseService";
import ComentsService from "@/services/odoo/ComentsService";
import GenericBtn from "../../components/generic-btn/index";

export default {
  name: "AtendimentoInserir",
  components: {
    FormFileUpload,
    GenericBtn,
  },
  data: () => ({
    defaultErrorObject: { text: "Erro ao inserir", type: "error" },
    caseData: {
      account: null,
      type: null,
      subject: null,
      description: "",
      attachments: [],
      contract: null,
      beneficiaryOwnerId: null,
      beneficiaryOwnerName: null,
      attendeeName: null
    },
    beneficiarySelected: null,
    carrierId: null,
    applicants: [],
    contracts: [],
    rules: formrules,
    busy: false,
    dialog: false,
  }),
  watch: {
    'beneficiarySelected' (val) {
      if(val) {
        this.caseData.beneficiaryOwnerId = val.beneficiaryId;
        this.caseData.beneficiaryOwnerName = val.beneficiaryOwnerName;
        this.caseData.attendeeName = val.personName;
      }

    }
  },
  computed: {
    ...mapState(["window"]),
    ...mapGetters(["isMobileSize"]),
  },
  methods: {
    ...mapMutations(["showmsg", "loading"]),
    setAttachments(attachments) {
      this.caseData.attachments = attachments;
    },
    async createTicket() {
      if (!this.$refs.ticketForm.validate()) {
        setTimeout(this.$refs.ticketForm.resetValidation, 3000);
        return;
      }

      this.loading(true);
      const configuredCaseData = {
        xipp_contract_id: {
          id: parseInt(this.caseData.contract.value),
        },
        xipp_beneficiary_id: this.beneficiarySelected && this.beneficiarySelected ? this.beneficiarySelected.attendeeID : null,
        partner_id: {
          id: parseInt(this.contracts.find((contract) => contract.value === this.caseData.contract.value).accountID),
        },
        name: this.caseData.subject,
        ticket_type_id: this.caseData.type,
        origin: "eu_protegido",
        description: this.caseData.description,
        xipp_attendee_name: this.caseData.attendeeName,
        xipp_email_web: $auth.user().email,
        xipp_beneficiary_owner_id: this.caseData.beneficiaryOwnerId,
        xipp_beneficiary_owner_name: this.caseData.beneficiaryOwnerName,
      };

      let emptyFile = false;
      let emptyFileNames = "";
      const attachments = this.caseData.attachments.map((file) => {
        if (!file.body.split(",")[1]) {
          emptyFile = true;
          emptyFileNames += file.name + "<br/>";
        }

        let fileName = file.name.substring(0, file.name.lastIndexOf(".")) || "";

        let f = {
          name: fileName,
          datas: file.body.split(",")[1],
          description: "CLIENT",
        };

        return f;
      });

      if (emptyFile) {
        this.showmsg({
          text:
            "Você selecionou arquivo(s) vazio(s) (0KB).<br/>Remova-o(s) antes de prosseguir.<br/><br/><b>" +
            emptyFileNames +
            "<b>",
          type: "error",
        });
        this.loading(false);
        return;
      }

      let caseEvent = {
        caseObject: configuredCaseData,
        files: attachments,
      };

      await this._caseService
        .CreateCaseWithAttachment(caseEvent)
        .then((response) => {
          this.$router.push(`/atendimento/${response.data}`);
        })
        .catch(() => this.showmsg(this.defaultErrorObject))
        .finally(() => this.loading(false));
    },
    async loadBenefits() {
      let result = null;
      if (this.$auth.user().beneficiaries && this.$auth.user().beneficiaries.length > 0) {
        result = $auth.user().beneficiaries.map((beneficiary) => {
          return {
            planId: beneficiary.planId,
            carrierId: beneficiary.carrierId,
            beneficiaryID: beneficiary.beneficiaryId,
            attendeeID: beneficiary.beneficiaryId,
            accountID: beneficiary.accountId,
            value: beneficiary.contractId,
            text: `${beneficiary.benefitName} / ${beneficiary.carrierName}`,
          };
        });
      } else {
        result = $auth.user().dependents.map((dependent) => {
          return {
            planId: dependent.planId,
            carrierId: dependent.carrierId,
            beneficiaryID: dependent.beneficiaryId,
            attendeeID: dependent.dependentId,
            accountID: dependent.accountId,
            value: dependent.contractId,
            text: `${dependent.benefitName} / ${dependent.carrierName}`,
          };
        });
      }
      this.contracts.push(...result);
      this.filterContractByCarrierId();
    },
    filterContractByCarrierId() {
      if(this.carrierId && this.contracts && this.contracts.length) {
        this.contracts = this.contracts.filter(contract => contract.carrierId === this.carrierId.toString())
        if(this.contracts.length) {
          this.caseData.contract = this.contracts[0];
          this.loadApplicants();
        }
      }
    },
    loadApplicants() {
      this.beneficiarySelected = null;
      const sessionUser = JSON.parse(localStorage.getItem("SESSION")).user
      let applicantsList = [];

      if (sessionUser) {
        if (sessionUser.beneficiaries) {
          let beneficiaries = sessionUser.beneficiaries.filter(bcp => bcp.planId === this.caseData.contract.planId)
          beneficiaries = beneficiaries.map((beneficiary) => ({
            personName: beneficiary.personName,
            personCPF: beneficiary.personCPF,
            beneficiaryId: beneficiary.beneficiaryId,
            attendeeID: beneficiary.beneficiaryId,
            beneficiaryName: beneficiary.personName,
            dependents: beneficiary.dependents
          }))
          applicantsList = [...applicantsList, ...beneficiaries]

          if(beneficiaries.length) {
            beneficiaries.forEach(beneficiary => {
              if(beneficiary.dependents && beneficiary.dependents.length) {
                beneficiary.dependents.forEach((dependent) => {
                  if(this.$util.isDependentGreaterThanAgeLimit(dependent.personBirthDate)){
                    applicantsList.push({
                      personName: dependent.personName,
                      personCPF: dependent.personCPF,
                      beneficiaryId: dependent.beneficiaryId,
                      attendeeID: dependent.dependentId,
                      beneficiaryName: beneficiary.personName,
                    });
                  }
                })
              }
            });
          }
        }

        if (sessionUser.dependents) {
          let dependents = sessionUser.dependents.filter(dcp => dcp.planId === this.caseData.contract.planId)
          dependents = dependents.map((dependent) => ({
            personName: dependent.personName,
            personCPF: dependent.personCPF,
            beneficiaryId: dependent.beneficiaryId,
            attendeeID: dependent.dependentId,
            beneficiaryName: dependent.cardHolderName,
          }))
          applicantsList = [...applicantsList, ...dependents]
        }
      }

      this.applicants = applicantsList;

      this.caseData.beneficiaryOwnerId = this.applicants[0].beneficiaryId;
      this.caseData.beneficiaryOwnerName = this.applicants[0].beneficiaryName;
    },
  },
  async created() {
    this._caseService = new CaseService();
    this._comentsService = new ComentsService();
  },
  async beforeMount() {
    if (this.$route.params) {
      this.caseData.subject = this.$route.params.optionTitle;
      this.caseData.type = this.$route.params.optionType;
      this.carrierId = this.$route.params.carrierId;
    }
    await this.loadBenefits();
  },
};
</script>
<style scoped>
@media (min-height: 569px) {
  .btn-position {
    margin-top: -70px;
  }
}

@media (min-height: 639px) {
  .btn-position {
    margin-top: 30px;
  }
}

@media (min-height: 730px) {
  .btn-position {
    margin-top: 8vh;
  }
}

@media (min-height: 822px) {
  .btn-position {
    margin-top: 20vh;
  }
}

@media (min-height: 661px) {
  .btn-position {
    margin-top: 15vh;
  }
}

@media (min-height: 735px) {
  .btn-position {
    margin-top: 20vh;
  }
}

@media (min-height: 800px) {
  .btn-position {
    margin-top: 28vh;
  }
}
</style>
